<template>
  <div class="iframe-container">
    <meta charset="utf-8" />
    <link
      type="text/css"
      rel="stylesheet"
      href="https://source.zoom.us/2.0.1/css/bootstrap.css"
    />
    <link
      type="text/css"
      rel="stylesheet"
      href="https://source.zoom.us/2.0.1/css/react-select.css"
    />
    <meta name="format-detection" content="telephone=no" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
    />
  </div>
</template>

<script>
import { ZoomMtg } from "@zoomus/websdk";

ZoomMtg.setZoomJSLib("https://source.zoom.us/2.0.1/lib", "/av");
// ZoomMtg.setZoomJSLib("https://source.zoom.us/1.9.9/lib", "/av");
// ZoomMtg.setZoomJSLib("node_modules/@zoomus/websdk/dist/lib", "/av");
ZoomMtg.preLoadWasm();
ZoomMtg.prepareJssdk();

console.log(ZoomMtg);
console.log("checkSystemRequirements");
console.log(JSON.stringify(ZoomMtg.checkSystemRequirements()));

export default {
  data: () => ({
    exit: `${window.location.protocol}//${window.location.host}/admin/zoom-ended`,
    meetConfig: "",
    signature: "",
    zoom: "",
    paoEmail: 'secretariat_pao@globelines.com.ph'
  }),
  created() {
    console.log(this.$route.params.meeting_id);
    console.log(this.$route.params.password);
    console.log(this.$route.params.zoom_key);
    console.log(this.$route.params.zoom_secret);
    console.log(this.$route.params.name);
    console.log(this.$route.params.email);
    console.log(window.location.host);

    const password = this.$route.params.password.replace(/\s/g, "");

    // Meeting config object
    document.getElementById("zmmtg-root").style.display = "block";
    this.meetConfig = {
      apiKey: this.$route.params.zoom_key, //
      apiSecret: this.$route.params.zoom_secret,
      meetingNumber: this.$route.params.meeting_id,
      userName: this.$route.params.name, //
      passWord: password,
      leaveUrl: this.exit,
      role: 1, // 1 - Host 0 - Attendee
    };

    // Generate signature
    this.signature = ZoomMtg.generateSignature({
      meetingNumber: this.meetConfig.meetingNumber,
      apiKey: this.meetConfig.apiKey,
      apiSecret: this.meetConfig.apiSecret,
      role: this.meetConfig.role,
      success: function (res) {
        console.log("success signature: " + res.result);
      },
    });
    /***
     *
     *
     */
    ZoomMtg.init({
      leaveUrl: this.exit,
      isSupportAV: true,
      isSupportBreakout: true,
      screenShare: true, //optional,
      showMeetingHeader: true,
      disableCORP: !window.crossOriginIsolated,
      success: () => {
        ZoomMtg.i18n.load("en-US");
        ZoomMtg.i18n.reload("en-US");
        ZoomMtg.join({
          meetingNumber: this.meetConfig.meetingNumber,
          userName: this.meetConfig.userName,
          signature: this.signature,
          apiKey: this.meetConfig.apiKey,
          userEmail: this.paoEmail, //
          passWord: this.meetConfig.passWord,
          success: (res) => {
            ZoomMtg.record({
              record: true,
            });
            console.log("Join meeting success" + res);
          },
          error: (res) => {
            console.log("Error joining" + res);
          },
        });
      },
      error: (res) => {
        console.log("dito and error" + res);
      },
    });

    ZoomMtg.inMeetingServiceListener("onUserJoin", function (data) {
      console.log("inMeetingServiceListener onUserJoin", data);
    });

    ZoomMtg.inMeetingServiceListener("onUserLeave", function (data) {
      /**
       * For typical user
       */
      console.log(data);
    });

    ZoomMtg.inMeetingServiceListener("onUserIsInWaitingRoom", function (data) {
      console.log("inMeetingServiceListener onUserIsInWaitingRoom", data);
    });

    ZoomMtg.inMeetingServiceListener("onMeetingStatus", function (data) {
      /***
       * Meeting status 1 - waiting
       * Meeting status 2 - start
       * Meeting status 3 - not started ended
       *
       */
      console.log(
        "inMeetingServiceListener onMeetingStatus",
        data.meetingStatus
      );
    });
  },
};
</script>

<style scoped>
</style>